import React, { useRef, useState } from "react";
import portfolioData from "./Data";
import "./home.scss";
import styled from "styled-components";
import { gsap } from "gsap";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import useScreenSize from "./useWidth";

export const Home = () => {
  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [project, setProject] = useState({});
  const app = useRef();
  const block = useRef();
  const sidebar = useRef();
  const topContent = useRef();
  const width = useScreenSize();

  // console.log(topContent && topContent.current.clientHeight)

  const isMobile = width.width < 768;

  const handleOpen = (e) => {
    gsap.to(block.current, { right: "0" });
  };

  const handleClose = (e) => {
    gsap.to(block.current, { right: "-1000px" });
  };

  const openSidebar = (e) => {
    openMenu
      ? gsap.to(sidebar.current, { marginLeft: isMobile ? "-100vw" : "-35vw" })
      : gsap.to(sidebar.current, { marginLeft: "0vw" });
  };

  return (
    <Container ref={app}>
      <HomeContent>
        <TopNav ref={topContent}>
          <AboutButton
            onClick={() => {
              openSidebar();
              setOpenMenu(!openMenu);
            }}
          >
            About
          </AboutButton>
          <Name>Sam Gildea</Name>
        </TopNav>
        <Sidebar ref={sidebar}>
          <SidebarClose
            onClick={() => {
              openSidebar();
              setOpenMenu(!openMenu);
            }}
          />

          <AboutInfo>
            Developer/Designer. <br />
            Computer Science/Design at Northeastern University.
            <br />
            NYC/BOSTON
          </AboutInfo>
        </Sidebar>

        {open && (
          <ProjectBlock ref={block}>
            <ProjectInfo>
              <HamburgerIcon onClick={() => handleClose()} />
              <ProjectTitle>{project.title} </ProjectTitle>
              <ProjectTimeline>{project.timeline}</ProjectTimeline>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ProjectStack>
                {project.stack && project.stack.map((tool) => <li>{tool}</li>)}
              </ProjectStack>

              {project.images &&
                project.images.map((image) =>
                  project.id === 2 || project.id === 0 ? (
                    <div>
                      <video autoPlay muted loop key={image}>
                        <source src={image} />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <div>
                      <img
                        className="project__image"
                        alt={project.title}
                        src={image}
                      />
                    </div>
                  )
                )}
            </ProjectInfo>
          </ProjectBlock>
        )}
        <ListItemsContainer>
          <ListItems>
            {portfolioData.projects.map((project) => (
              <ListItem
                onClick={() => {
                  setOpen(true);
                  handleOpen();
                  setProject(project);
                }}
              >
                {project.title}
              </ListItem>
            ))}
          </ListItems>
        </ListItemsContainer>
      </HomeContent>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fdfdfd;
`;

const AboutButton = styled.button`
  position: absolute;
  background-color: white;
  border: none;
  left: 48px;
  font-size: 24px;
  color: #ee312f;

  &:focus {
    outline: 0;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    left: 24px;
    font-size: 16px;
    padding: 0px;
  }
`;

const ProjectInfo = styled.div`
  position: relative;
`;

const ProjectTitle = styled.h2`
  padding-top: 48px;
  padding-left: 48px;
  font-size: 48px;
  width: 75%;

  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 32px;
    padding-top: 24px;
  }
`;

const AboutInfo = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  font-size: 32px;
`;

const ProjectDescription = styled.div`
  font-size: 24px;
  padding-top: 8px;
  padding-right: 48px;
  padding-left: 48px;
  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 18px;
    padding-top: 8px;
  }
`;

const HamburgerIcon = styled(Hamburger)`
  right: 48px;
  top: 52px;
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 32px;

  svg {
    fill: red;
  }
  @media (max-width: 768px) {
    right: 24px;
    top: 24px;
  }
`;

const SidebarClose = styled(Hamburger)`
  right: 24px;
  top: 24px;
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 32px;
`;

const ProjectBlock = styled.div`
  width: 50vw;
  height: 100vh;
  z-index: 2;
  position: fixed;
  top: 72px;
  overflow-x: hidden;
  border-left: 1px solid #ee312f;
  right: -1000px;
  background-color: #fdfdfd;
  overscroll-behavior: contain;

  video {
    width: 100%;
  }

  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ProjectTimeline = styled.div`
  color: #ee312f;
  font-size: 18px;
  padding-left: 48px;
  padding-top: 8px;

  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 16px;
  }
`;

const Sidebar = styled.div`
  width: 35vw;
  height: 100vh;
  margin-left: -35vw;
  border-right: 1px solid #ee312f;
  position: fixed;
  background-color: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 3;

  @media (max-width: 768px) {
    width: 100vw;
    margin-left: -100vw;
  }
`;

const ProjectStack = styled.div`
  padding-left: 48px;
  padding-top: 16px;
  font-size: 24px;
  padding-bottom: 24px;

  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 18px;
    padding-bottom: 16px;
  }
`;

const TopNav = styled.div`
  background-color: white;
  width: 100vw;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ee312f;
`;

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #ee312f;
  overscroll-behavior: contain;
  font-weight: lighter;
`;

const ListItems = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.button`
  cursor: pointer;
  font-size: 48px;
  color: #ee312f;
  text-decoration: none;
  padding-top: 48px;
  padding-left: 48px;
  padding-bottom: 48px;
  line-height: 1;
  background-color: #fdfdfd;
  height: fit-content;
  border-bottom: 1px solid #ee312f;
  border-top: none;
  border-left: none;
  border-right: none;
  transition: color 0.5s ease;
  transition: background-color 0.5s;
  text-align: left;
  transition: all 0.5s ease;

  &:focus {
    outline: 0;
    color: white;
    background-color: #ee312f;
  }

  &:hover {
    color: white;
    background-color: #ee312f;
    padding-top: 64px;

    padding-bottom: 64px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    padding-left: 24px;
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

const Name = styled.div`
  font-size: 32px;
  color: #ee312f;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-weight: lighter;
  line-height: 1;
  height: fit-content;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const ListItemsContainer = styled.div`
  overflow-y: scroll;
  height: fit-content;
  margin-top: 72px;
  width: 100vw;
  overscroll-behavior: contain;
`;
