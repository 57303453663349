const portfolioData = {
  projects: [
    {
      id: 0,
      url: "sto",
      title: "Stō",
      image: "https://i.imgur.com/clOmaQW.png",
      description:
        "Worked directly with design team, developed an animated, responsive website to highlight portable scooter folding technology.",
      timeline: "September - December 2021",
      stack: ["React.js", "GraphQL", "GSAP", "Prismic CMS"],
      images: ["https://i.imgur.com/VNKWO1d.mp4"],
    },
    {
      id: 1,
      url: "conference",
      title: "Interventions: Disrupt",
      image: "https://i.imgur.com/clOmaQW.png",
      description:
        "Worked directly with conference organizers and designers to develop landing page for an annual conference hosted by Scout, a student-led design organization.",
      timeline: "November - December 2020",
      stack: ["Gatsby.js", "GraphQL", "SCSS", "Prismic CMS"],
      images: [
        "https://i.imgur.com/clOmaQW.png",
        "https://i.imgur.com/ZICM5ug.png",
        "https://i.imgur.com/TkP3flv.png",
        "https://i.imgur.com/MpTnFSq.png",
      ],
    },
    {
      id: 2,
      url: "ronik",
      title: "Ronik Design Landing Page",
      image: "https://i.imgur.com/aoE546Y.png",
      description:
        "During my co-op at Ronik Design in Brooklyn I developed their temporary landing page.",
      timeline: "Februrary - March 2020",
      stack: ["Three.js", "HTML", "CSS"],
      images: ["https://i.imgur.com/bwCzqcd.mp4"],
    },
    {
      id: 3,
      url: "lionheart",
      title: "Lionheart Foundation",
      image: "https://i.imgur.com/E20oxAg.png",
      timeline: "November 2019 - May 2020",
      description:
        "Working with non-profit that helps teen parents and at-risk youth to build an app that serves as a platform for all educational content. Developing mobile application which allows admins to update content and customize app using headless CMS. ",
      stack: ["React Native", "Takeshape CMS", "Expo"],
      images: [
        "https://i.imgur.com/DdXepKL.png",
        "https://i.imgur.com/EMUeKez.png",
      ],
    },
    {
      id: 4,
      url: "nomix",
      title: "Nomix Life Sciences",
      image: "https://i.imgur.com/KfZpFVs.png",
      description:
        "Worked with medical startup that connects patients with ideal clinical trials. Communicated closely with experienced backend and algorithm developers to provide an intuitive web experience for patients. Developed responsive website working with design team.  Incorporated e-commerce functionality with Shopify API. ",
      timeline: "September - December 2019",
      stack: [
        "React.js",
        "GraphQL",
        "Sass",
        "Netlify",
        "Shopify Storefront API",
      ],
      images: [
        "https://i.imgur.com/n5Ghlj1.png",
        "https://i.imgur.com/koc5Z5m.png",
        "https://i.imgur.com/dlkLFmj.png",
        "https://i.imgur.com/VAXM6KY.png",
      ],
    },
    {
      id: 5,
      url: "slate",
      title: "Slate Chocolate Milk",
      image: "https://i.imgur.com/TF1ka0q.png",
      timeline: "January - May 2019",
      description:
        "Worked with design team to help founders establish brand identity and developed responsive website with e-commerce functionality using the Shopify API. Used headless CMS to store manage content on site. ",
      stack: ["Gatsby.js", "GraphQL", "Sass", "Netlify"],
      images: [
        "https://i.imgur.com/WGmOP0f.png",
        "https://i.imgur.com/0WzGfae.png",
      ],
    },
    {
      id: 6,
      url: "turbonomic",
      title: "Turbonomic",
      image: "https://i.imgur.com/E4GT8FG.png",
      timeline: "October 2017 - August 2019",
      description:
        "Working directly with product marketing team designed, developed and maintained website. Used Wordpress CMS to create custom templates in PHP, which allowed marketing team to edit content. Allowed full customization for entire website and increased traffic by designing pages such as the homepage and product page.",
      stack: ["PHP", "HTML", "CSS", "Wordpress", "JQuery", "JavaScript"],
      images: [
        "https://i.imgur.com/LshCpvf.png",
        "https://i.imgur.com/iDSLRUo.png",
      ],
    },
  ],
};

export default portfolioData;
